<template>
  <Header />
  <router-view />
  <Footer /> 
</template>

<script>
import Footer from './components/footer.vue'
import Header from './components/header.vue'
export default {
  components: { Header, Footer }
}
</script>

<style>
</style>
