<template>
  <footer>
    <!-- Footer Area Start -->
    <section id="footer-Content">
      <div class="container">
        <!-- Start Row -->
        <div class="row">
          <!-- Start Col -->
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            <div class="footer-logo">
              <img src="@/assets/images/img/logo-oz.svg" alt="">
            </div>
          </div>
          <!-- End Col -->
          <!-- Start Col -->
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            <div class="widget">
              <h3 class="block-title">Mehnat M haqida</h3>
              <ul class="menu">
                <li><a href="#"> - Faoliyat uchun huquqiy asos(Nizom)</a></li>
                <li><a href="#">- Rahbariyat</a></li>
                <li><a href="#">- Tashkiliy tuzilma</a></li>
                <li><a href="#">- Bo'sh ish o'rinlar</a></li>
                <li><a href="#">- Tenderlar</a></li>
              </ul>
            </div>
          </div>
          <!-- End Col -->
          <!-- Start Col -->
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            <div class="widget">
              <h3 class="block-title">Murojaat</h3> 
              <ul class="menu">
                <li><a href="#"> - Murojaat qilish tartib</a></li>
                <li><a href="#">- Murojaat holatini tekshirish</a></li>
              </ul>
              <!-- <h3 class="block-title">Yangiliklar</h3> -->
              <h3 class="block-title mt-5">Yangiliklar</h3>
            </div>
          </div>
          <!-- End Col -->
          <!-- Start Col -->
          <!-- <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-mb-12">
             <div class="widget">
               
             </div>
           </div> -->
          <!-- End Col -->
          <!-- Start Col -->
          <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            <div class="widget">
              <h3 class="block-title">
                Interaktiv xizmatlarga <br>
                qisqacha tushuntirishlar
              </h3>
              <ul class="menu">
                <li><a href="#"> - Ko'p uchraydigan savollar</a></li>
                <li>
                  <a
                    href="#"
                  >- Mehnat sohasiga oid qonunchilikka <br>
                    kiritilgan o'zgarishlar</a>
                </li>
                <li><a href="#">- Bilimni baholash</a></li>
              </ul>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- Copyright Start  -->

      <!-- <div class="copyright">

      </div> -->
      <!-- Copyright End -->
    </section>
    <!-- Footer area End -->
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
