<template>
  <header id="home" :class="isHome?'hero-area':'header-area'">
    <div v-if="isHome" class="overlay">
      <span />
      <span />
    </div>
    <nav
      class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar"
      :class="visible||!isHome?'menu-bg':''"
    >
      <div class="container">
        <a
          href="/"
          class="navbar-brand"
        ><img
          src="@/assets/images/img/logo-ozd.svg"
          alt=""
        ></a>
        <button
          class="navbar-toggler dropdown"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="lni-menu" />
        </button>

        <div id="navbarCollapse" class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto w-100 justify-content-end">
            <li class="dropdown show nav-item">
              <a
                id="dropdownMenuLink"
                class="dropdown-toggle nav-link"
                aria-haspopup="true"
                aria-expanded="false"
                style="cursor:pointer;"
                @click="goToRoute('AboutInfo')"
              >Mehnat M haqida
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a
                  class="dropdown-item"
                  href="#"
                >Faoliyat uchun huquqiy <br>
                  asos(Nizom)</a>
                <!-- <a class="dropdown-item border_top" href="#">Rahbariyat</a>
                <a
                  class="dropdown-item border_top"
                  href="#"
                >Tashkiliy tuzilma</a>
                <a
                  class="dropdown-item border_top"
                  href="#"
                >Bo'sh ish o'rinlar</a>
                <a class="dropdown-item border_top" href="#">Tenderlar</a> -->
              </div>
            </li>
            <!-- <li class="dropdown show nav-item">
              <a
                id="dropdownMenuLink"
                class="btn btn-secondar dropdown-toggle nav-link page-scroll"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >Murojaat</a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="#">Murojaat qilish tartibi</a>
                <a
                  class="dropdown-item border_top"
                  href="#"
                >Murojaat holatini tekshirish</a>
              </div>
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link" href="">Yangiliklar</a>
            </li> -->
            <!-- <li class="dropdown show nav-item">
              <a
                id="dropdownMenuLink"
                class="btn btn-secondar dropdown-toggle nav-link page-scroll"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >Interaktiv xizmatlar</a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a
                  class="dropdown-item"
                  href="#"
                >Aktual munosabatlarga qisqacha <br>
                  tushuntirishlar</a>
                <a
                  class="dropdown-item border_top"
                  href="#"
                >Ko'p uchraydigan savollar</a>
                <a
                  class="dropdown-item border_top"
                  href="#"
                >Mehnat sohasiga oid <br>
                  qonunchilikka kiritilgan o'zgarishlar</a>
                <a
                  class="dropdown-item border_top"
                  href="#"
                >Bilimni baholash</a>
              </div>
            </li> -->
            <!-- <li class="dropdown show nav-item">
              <a
                id="dropdownMenuLink"
                class="btn btn-secondar dropdown-toggle nav-link page-scroll"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >Uz</a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="#">Ру</a>
                <a class="dropdown-item border_top" href="#">En</a>
                <a class="dropdown-item border_top" href="#">Уз</a>
              </div>
            </li> --> 
            <li class="nav-item">
              <a class="btn btn-singin" @click="goToRoute('AppointmentCreate')">Qabulga yozilish</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-singin" href="https://admin-online.mehnat.uz/">Kirish</a>
            </li>
          </ul>
        </div>
      </div>  
    </nav>
    <div v-if="isHome" class="container">
      <div class="row space-100">
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="contents">
            <h2 class="head-title">" Mehnat M " loyihasi</h2>
            <p>
              Toshkent shahar Bandlik bosh boshqarmasidagi Davlat mehnat
              inspeksiyasi tizimidagi “Mehnat sohasidagi murojaat va nizolarni
              o‘rganish hamda hal qilishga ko‘maklashish markazi”
            </p>
            <div class="header-button">
              <a
                class="btn btn-border-filled"
                @click="goToRoute('ApplicationCreate')"
              >Ariza qoldirish</a>
              <a            
                class="btn btn-border page-scroll"
                @click="goToRoute('CheckApplication')"
              >Ariza holatini tekshirish</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12 p-0">
          <div class="intro-img">
            <img src="@/assets/images/img/intro.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <a v-if="is_top" href="#" class="back-to-top">
      <i class="lni-chevron-up" />
    </a>
  </header>
</template>

<script>
import global from '@/mixins/global.js'
export default {
  mixins: [global],
  data() {
    return {
      visible: false,
      is_top: false,
      drawer: false
    }
  },
  computed: {
    isHome() {
      return this.$route.name === 'Home'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollListener() {
      if (this.isHome) {
        this.visible = window.scrollY > 10
      } else {
        this.visible = true
      }
      this.is_top = window.scrollY > 150
    }
  }
}
</script>

<style>

</style>
